import { navigate, Link } from "gatsby";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import {
  RealmAppProvider,
  useRealmApp,
  APP_ID,
  OnboardingRedirector,
} from "../../components/realm_app";
import { validateEmail } from "../../utils";
import Navbar from "../../components/onboarding_navbar";
import { Footer } from "../../components/footer";
import { Form } from "../../components/Form/Form";
import "../../index.css";

const LoginPage = () => {
  const realmApp = useRealmApp();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState("");

  //TODO: Think of better place to store this data
  const formContent = [
    { heading: "Email:", type: "email", value: email, func: setEmail },
    {
      heading: "Password:",
      type: "password",
      value: password,
      func: setPassword,
    },
  ];

  async function handleLogin(event) {
    event.preventDefault();
    setLoading("Logging in...");

    try {
      await realmApp.LogIn(email, password);
      navigate("/member/dashboard");
    } catch (err) {
      console.log(["Error", JSON.stringify(err)]);
      if (err.error == "confirmation required") {
        alert(
          "Please confirm your email by clicking on the confirmation" +
            " email sent to your inbox."
        );
      } else {
        alert("Error: " + err.error);
      }
    }
    setLoading("");
  }
  async function handleResendConfirmation() {
    setLoading("resending confirmation email...");
    try {
      validateEmail(email);
      try {
        await realmApp.app.emailPasswordAuth.resendConfirmationEmail(email);
      } catch (e) {
        throw e.error;
      }
      setLoading("Confirmation email resent. Please check your inbox.");
    } catch (err) {
      alert(err);
      setLoading("");
    }
  }

  return (
    <>
      <main className="page">
        <Navbar />
        <div className="page-content-center">
          <h3>Log In</h3>
        </div>
        <div className="page-content-center">
          <div className="page-content page-content-small">
            <Form onSubmitFunc={handleLogin} formContent={formContent} />
          </div>
        </div>
        <h6 className="page-content-center">
          <Link className="link" to="/member/forgot_password/">
            Reset Password
          </Link>
        </h6>
        <h6 className="page-content-center">
          <Link className="link" to="/member/signup/">
            Sign Up
          </Link>
        </h6>
        <div className="page-content-center">{loading}</div>
      </main>
      <Footer />
    </>
  );
};

export default ({ location }) => {
  return (
    <RealmAppProvider appId={APP_ID}>
      <OnboardingRedirector location={location}>
        <LoginPage />
      </OnboardingRedirector>
    </RealmAppProvider>
  );
};
